<template>
  <div>
    <section class="vh-100">
      <div class="container mt-2 h-90">
        <b-row class="text-center mt-4 mb-4">
          <b-col>
            <h1 style="font-size: 45px; font-weight: 900; color: white">
              Create your <span style="color: #f7981e"> Account Now.</span>
            </h1>
          </b-col>
        </b-row>
      </div>

      <div class="container h-50">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card" style="border-radius: 1rem">
              <b-row class="text-center mt-4">
                <b-col>
                  <img src="../assets/images/webpatrol.png" alt="Web Patrol" style="height:10vh;" />
                </b-col>
              </b-row>
              <div class="card-body p-4 text-left">
               




                <!-- NEW CHANGES STARTS -->

                <form action="" @submit.prevent="Register()" @keydown.enter="focusNext">
  <!-- Full Name -->
  <div class="form-outline mb-4">
    <label class="form-label" for="typeUsername-2">Your full name <span class="required">*</span></label>
    <input v-model="input.username" :state="validateFullName" maxlength="50" type="text"
      id="typeUsername-2" class="form-control form-control-lg" placeholder="e.g John Doe" ref="fullname" />
    <b-form-invalid-feedback :state="validateFullName">{{ fullname_err_text }}</b-form-invalid-feedback>
  </div>

  <!-- Email -->
  <div class="form-outline mb-4">
    <label class="form-label" for="typeEmailX-2">Your e-mail <span class="required">*</span></label>
    <input v-model="input.email" type="email" maxlength="50" id="typeEmailX-2"
      class="form-control form-control-lg" placeholder="e.g info@example.com"
      @input="input.email = input.email.toLowerCase()" ref="email" />
  </div>

  <!-- New Password -->
  <div class="form-outline mb-4">
    <label class="form-label" for="typePasswordX-2">Enter new password <span class="required">*</span></label>
    <b-input-group>
      <b-form-input v-model="input.password" type="password" id="newpassword"
        class="form-control form-control-lg" placeholder="**********" maxlength="30"
        :state="validatePassword" ref="password" />
      <b-input-group-append is-text @click="togglePassword('newpassword')">
        <i v-show="showPassword" class="icofont icofont-eye" title="hide password"></i>
        <i v-show="!showPassword" class="icofont icofont-eye-blocked" title="show password"></i>
      </b-input-group-append>
    </b-input-group>
  </div>

  <!-- Confirm Password -->
  <div class="form-outline mb-4">
    <label class="form-label" for="typePasswordX-2">Re-enter password <span class="required">*</span></label>
    <b-input-group>
      <b-form-input v-model="verify_password" type="password" id="reenterpassword"
        class="form-control form-control-lg" placeholder="**********" maxlength="30"
        :state="validatePassword" ref="confirmPassword" />
      <b-input-group-append is-text @click="togglePassword('reenterpassword')">
        <i v-show="showPassword" class="icofont icofont-eye" title="hide password"></i>
        <i v-show="!showPassword" class="icofont icofont-eye-blocked" title="show password"></i>
      </b-input-group-append>
    </b-input-group>
  </div>

  <!-- Register Button -->
  <b-button class="btn btn-lg btn-block btn-success" type="submit">
    Register Now
  </b-button>
</form>


                <!-- NEW CHANGES ENDS -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LoginController from './../../services/LoginControlService'
import Config from "../../services/config"
    export default {
        name: 'signUp',
        data(){
          return{
            showPassword:false,
            fullname_err_text:'',
            verify_password:'',
            input:{email:'',username:'',password:''},
            googleLogin:''
          }
        },
        mounted(){
          if(this.$route.query.email){
            this.input.email = this.$route.query.email
          }
            this.googleLogin = Config.googleLogin
        },
        computed:{
          validateFullName() {
            var fullName = this.input.username
            const regex = /^[a-zA-Z0-9 ]+$/; // Alphanumeric and space characters only
            const maxLength = 50; // Maximum length of the full name field
            if (fullName.length >= maxLength) {
              this.fullname_err_text = "Full name cannot exceed "+maxLength+" characters"
              return false;
            }
            else if (!regex.test(fullName) && fullName != '') {
              this.input.username = ''
              this.fullname_err_text = "Full name can only contain alphanumeric characters and spaces";
              return false;
            }
            return true;
          },
          validatePassword(){
            if(this.input.password && this.input.password.length >=30){
                this.$toasted.show('Characters Limit Exceeded!', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
        }
        },
        methods: {
        
        
        focusNext(event) {
  const inputs = [
    this.$refs.fullname,
    this.$refs.email,
    this.$refs.password,
    this.$refs.confirmPassword
  ];

  const currentIndex = inputs.indexOf(event.target);

  // Stop if current field is invalid or empty
  if (currentIndex !== -1) {
    if (!this.validateCurrentField(currentIndex)) {
      event.preventDefault(); // Prevent focus shift if invalid
      return;
    }

    // Focus on the next input if available and current input is valid
    if (currentIndex < inputs.length - 1) {
      inputs[currentIndex + 1].focus();
      event.preventDefault(); // Prevent form submission
    }
  }
},

validateCurrentField(index) {
  if (index === 0) {
    if (!this.input.username.trim()) {
      this.fullname_err_text = "Full name is required";
      return false;
    }
  }

  if (index === 1) {
    if (!this.input.email.trim()) {
      this.$toasted.show("Email is required", {
        theme: "outline",
        position: "bottom-center",
        type: "error",
        duration: 2000
      });
      return false;
    }
    if (!this.validateEmail(this.input.email)) {
      this.$toasted.show("Invalid Email", {
        theme: "outline",
        position: "bottom-center",
        type: "error",
        duration: 2000
      });
      return false;
    }
  }

  if (index === 2) {
    if (!this.input.password.trim()) {
      this.$toasted.show("Password is required", {
        theme: "outline",
        position: "bottom-center",
        type: "error",
        duration: 2000
      });
      return false;
    }
    if (this.input.password.length < 6) {
      this.$toasted.show("Password length should be more than 6 characters", {
        theme: "outline",
        position: "bottom-center",
        type: "error",
        duration: 2000
      });
      return false;
    }
  }

  if (index === 3) {
    if (this.input.password !== this.verify_password) {
      this.$toasted.show("Passwords do not match", {
        theme: "outline",
        position: "bottom-center",
        type: "error",
        duration: 2000
      });
      return false;
    }
  }

  return true;
},
        
        
        
          async Register(){
            if(this.validate()){
                let response= await LoginController.signup(this.input)
                if(response.result){
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
                    this.$router.push('/auth/login')
                }
                else{
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                }
            } 
          },
          validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
          },
          validate(){


            if (!this.input.email.trim() && !this.input.password.trim()) {
              this.$toasted.show("Please enter email address and password", {
                theme: "outline",
                position: "bottom-center",
                type: "error",
                duration: 2000,
              });
              return false;
            }

            
            
            


            if(this.validateEmail(this.input.email)){
              // if (/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(this.input.email) && !this.input.email.endsWith('@gmail.com')) {
              if(this.input.email == '' && this.input.password == ''){
                this.$toasted.show("Please enter a valid email address and password", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false;
              }

              if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/.test(this.input.email)) {
                this.$toasted.show("Invalid Email (Only lowercase letters allowed)", {
                  theme: "outline",
                  position: "bottom-center",
                  type: "error",
                  duration: 2000,
                });
                return false;
              }

              if(this.input.email && this.input.password.length === 0){
                this.$toasted.show("Password is required", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false;
              }

              if(this.input.email && this.input.password.length < 6){
                this.$toasted.show("password length should be more then 6 character", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false;
              }


              if(this.input.password != this.verify_password){
                this.input.password = ''
                this.verify_password=''
                this.$toasted.show("Password not match", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false;
              }

              if (/\s/.test(this.input.password)) {
                this.$toasted.show("Password should not contain spaces", {
                  theme: "outline",
                  position: "bottom-center",
                  type: "error",
                  duration: 2000,
                });
                return false;
              }

              

                return true;
            // }else{
            //   var el = document.querySelector('.google_btn')
            //   el.style.border = "3px solid #D93600";
            //   this.$toasted.show("Please signup with google account give below.", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            // }
          }else{
              this.$toasted.show("Invalid Email", {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
              return false;
            }
        },
        togglePassword(id){
          const password = document.getElementById(id);
          this.showPassword = this.showPassword === false ? true: false
          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
        }
        }
    }
</script>

<style>
body {
  background-image: url("../assets/images/login-bg.jpg") !important;
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  height: 100%;
}
</style>

<style scoped>
.card {
  box-shadow: 0 0 15px 10px #000000 !important;
}

.required {
  color: red;
  font-weight: bold;
}
button {
  background-color: #F7981E;
  color: #fefeff;
}
</style>



<!-- ---------------------------------------- -->


 